<template>
  <v-card>
    <v-card-title>
      <h3>Users</h3>
      <v-spacer />
      <v-checkbox v-if="isSuperAdmin"
        style="margin-top: 35px; margin-right: 15px;"
        v-model="showSwitchUsers"
        label="Only Switchable Users"
      />
      <v-text-field
        v-model="keywords"
        append-icon="mdi-magnify"
        label="Search Users..."
        clearable
        single-line
        hide-details
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="newUserForm" color="primary" dark fab>
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Invite New User</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <users-grid
        :keywords.sync="keywords"
        :client_id.sync="client_id"
        :showSwitchUsers.sync="showSwitchUsers"
        :reload.sync="reloadVar"
        @edit-user="editUser"
      />
    </v-card-text>
    <v-dialog v-model="userFormDialog" persistent max-width="600">
      <user-form
        :user.sync="formUser"
        @user-form-saved="
          closeUserForm();
          reloadGrid();
        "
        @user-form-closed="closeUserForm()"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import UsersGrid from "@/views/users/UsersGrid";
import UserForm from "@/views/users/UserForm";

export default {
  name: "users",
  components: {
    "users-grid": UsersGrid,
    "user-form": UserForm
  },
  computed: {
    isSuperAdmin() {
      return this.$store.getters.userForPortalAccess.user_type === "admin";
    }
  },
  data: () => ({
    keywords: "",
    selectedUser: {},
    reloadVar: 0,
    client_id: "",
    userFormDialog: false,
    formUser: {},
    showSwitchUsers: false
  }),
  methods: {
    reloadGrid: function() {
      this.reloadVar++;
    },
    newUserForm: function() {
      this.formUser = {};
      this.userFormDialog = true;
    },
    closeUserForm: function() {
      this.userFormDialog = false;
    },
    closeUserInviteForm: function() {
      this.userInviteFormDialog = false;
    },
    editUser: function(user) {
      this.formUser = user;
      this.formUser.confirm_email = this.formUser.email;
      this.userFormDialog = true;
    }
  }
};
</script>
